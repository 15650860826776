<template>
    <div class="container">
        <div class="condition">
            <div class="search_box">
                <div class="search">
                    <img class="search_icon" referrerpolicy="no-referrer" src="../../assets/img/搜索@2x.png" />
                    <!-- <span class="search_value"></span> -->
                    <input type="text" placeholder="关键词搜索" class="search_value" />
                </div>
            </div>
            <div class="time">
                <img class="time_icon" referrerpolicy="no-referrer" src="../../assets/img/日历@2x.png" />
                <span class="time_value">2024-09-06</span>
            </div>
            <img class="sort" referrerpolicy="no-referrer" src="../../assets/img/排序@2x.png" />
        </div>
        <div class="list">
            <ul>
                <li class="list_item">
                    <p class="template_name">我是模板名称</p>
                    <p class="note">备注信息：我是备注信息我是备注信息，我是备注信...</p>
                    <p class="line"></p>
                    <div class="tag_pre">
                        <span class="tag_content">预诊病例</span>
                    </div>
                </li>

                <li class="list_item">
                    <p class="template_name">我是模板名称</p>
                    <p class="note">备注信息：我是备注信息我是备注信息，我是备注信...</p>
                    <p class="line"></p>
                    <div class="tag_as">
                        <span class="tag_content">问诊病例</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.container {
    width: 345px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
    border-radius: 10px 10px 10px 10px;
    margin-top: 10px;
}

.condition {
    width: 345px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
}

.search_box {
    background-color: rgba(247, 247, 247, 1);
    border-radius: 4px;
    width: 192px;
    height: 26px;
    border: 1px solid rgba(238, 238, 238, 1);
    display: flex;
    align-items: center;
    margin: 0 8px;
}

.search {
    text-align: center;
    line-height: 26px;
    margin-left: 8px;
}

.search_icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
}

.search_value {
    width: 120px;
    height: 19px;
    overflow-wrap: break-word;
    color: rgba(152, 152, 152, 1);
    font-size: 13px;
    font-family: Source Han Sans-Regular;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    border: none;
    background-color: #f7f7f7;

    
}

.main {
    width: 365px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.time {
    background-color: rgba(247, 247, 247, 1);
    border-radius: 4px;
    width: 110px;
    height: 26px;
    border: 1px solid rgba(238, 238, 238, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.time_icon {
    width: 14px;
    height: 14px;
    margin: 0 5px;
}

.time_value {
    width: 67px;
    height: 19px;
    overflow-wrap: break-word;
    color: rgba(152, 152, 152, 1);
    font-size: 13px;
    font-family: Source Han Sans-Regular;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    line-height: 19px;
}

.sort {
    width: 26px;
    height: 26px;
    margin: 0 7px 0 7px;
}

.list {
    margin-top: 10px;
}

.list_item {
    width: 345px;
    height: 119px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 10px;
}

.template_name {
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 700;
    font-size: 17px;
    color: #1A1A1A;
    padding: 10px 15px;
}

.note {
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 400;
    font-size: 13px;
    color: #999999;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    padding: 0px 15px 10px 15px;

    /* 以下属性确保内容不换行，并在超出宽度时显示省略号 */
    white-space: nowrap;
    /* 防止文本换行 */
    overflow: hidden;
    /* 隐藏超出部分 */
    text-overflow: ellipsis;
    /* 显示省略号 */
}

.line {
    width: 345px;
    height: 1px;
    background: #F0F0F0;
    border-radius: 0px 0px 0px 0px;
}

.tag_pre {
    width: 120px;
    height: 26px;
    background: linear-gradient(90deg, #FFF0D6 0%, #FFF7E8 50%, rgba(255, 255, 255, 0) 100%);
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.tag_as {
    width: 120px;
    height: 26px;
    background: linear-gradient(90deg, #D5FAE8 0%, #E2FCEF 50%, rgba(255, 255, 255, 0) 100%);
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.tag_content {
    width: 52px;
    height: 19px;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 500;
    font-size: 13px;
    color: #F6A00D;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin: 15px;
}
</style>