<template>
    <div class="header_container mbform-drag" >
        <div class="logo">
            <img class="logo_img" src="../assets/img/默认头像-医师药师@2x.png" alt="">
        </div>
        <div class="text-container">
            <h3 class="title">{{ doctorInfo.orgName }}</h3>
            <h5 class="desc">{{ doctorInfo.name }}&nbsp;&nbsp;{{ doctorInfo.professionalTitle }}</h5>
        </div>
        <div class="ops">
            <ul>
                <li class="icon minimize" onclick="MinForm()"></li>
                <li class="icon close" onclick="CloseForm()"></li>
            </ul>
        </div>
    </div>
</template>

<script>
import { getDoctorInfo } from "@/api/header.js";
export default {
    data() {
        return {
            doctorInfo: {},
        }
    },
    methods: {
        getInfo() {
            getDoctorInfo().then((res) => {
                console.log(res)
                this.doctorInfo = res.data;
            });
        }
    },
    created() {
        this.getInfo();
    },
}
</script>

<style lang="less" scoped>
.header_container {
    width: 435px;
    height: 70px;
    background-image: url('../assets/img/背景@2x.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* 使内容在水平方向上分布 */
    position: relative;
    /* 使 .ops 绝对定位时基于此容器 */
}

.logo_img {
    width: 44px;
    height: 44px;
    margin: 0 13px;
    border-radius: 50%;
}

.text-container {
    flex: 1;
    /* 使文本容器占据剩余空间 */
}

.title {
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 26px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 4px;
}

.desc {
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 17px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.ops {
    position: absolute;
    /* 绝对定位 */
    top: 0;
    /* 靠上 */
    right: 0;
    /* 靠右 */
    display: flex;
    align-items: center;
    /* 垂直居中对齐 */
}

.ops ul {
    display: flex;
    list-style: none;
    /* 移除默认的列表样式 */
    padding: 0;
    /* 移除默认的内边距 */
    margin: 0;
    /* 移除默认的外边距 */
}

.ops li {
    width: 32px;
    height: 26px;
    margin-left: 5px;
    /* 为图标之间添加一些间距 */
}

.ops .minimize {
    background-image: url('../assets/img/缩小@2x.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.ops .close {
    background-image: url('../assets/img/关闭@2x.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
</style>