import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
import home from '../views/home/index.vue'
import diagnosis from '../views/diagnosis/index.vue'
import intelligent from '../views/intelligent/index.vue'
import log from '../views/log/index.vue'
import template from '../views/template/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    redirect: { name: 'log' },
    children: [
      {
        path: '/diagnosis',
        name: 'diagnosis',
        component: diagnosis,
      },
      {
        path: '/intelligent',
        name: 'intelligent',
        component: intelligent,
      },
      {
        path: '/log',
        name: 'log',
        component: log,
      },
      {
        path: '/template',
        name: 'template',
        component: template,
      },
      {
        path: '/popup/workDetail',
        name: 'workDetail',
        component: () => import('@/views/popup/workDetail')
      },
      {
        path: '/popup/diseaseDetail',
        name: 'diseaseDetail',
        component: () => import('@/views/popup/diseaseDetail')
      }
    ]
  },
  {
    path: '/login/index',
    name: 'login',
    component: () => import('@/views/login/index')
  },

  {
    path: '/popup/add',
    name: 'addPopup',
    component: () => import('@/views/popup/add')
  },
  {
    path: '/popup/moduleContent',
    name: 'moduleContent',
    component: () => import('@/views/popup/moduleContent')
  },
  {
    path: '/popup/newModule',
    name: 'newModule',
    component: () => import('@/views/popup/newModule')
  },


]

const router = new VueRouter({
  routes
})

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  const token = getToken(); // 获取token
  if (to.name !== 'login' && !token) {
    // 如果没有token并且不是访问登录页面，则跳转到登录页面
    next({ name: 'login' });
  } else {
    // 否则继续前进
    next();
  }
});

export default router
