<template>
  <div class="container">
    <div class="condition">
      <div class="select" @click="showPicker = true">
        <span class="all">{{ statuscolumns[flag].label }}</span>
        <img class="arrow" src="../../assets/img/箭头@2x.png" alt="" />
      </div>
      <div class="time">
        <img class="time_icon" referrerpolicy="no-referrer" src="../../assets/img/日历@2x.png" />
        <span class="time_value" @click="show = true">{{ date }}</span>
        <van-icon @click="resetDate" name="cross" style="margin-left: 10px; margin-top: 3px;" color="#989898"
          size="12px" />
      </div>
    </div>
    <van-calendar v-model="show" @confirm="onConfirm" color="#1989fa" :min-date="minDate" :max-date="maxDate"/>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" @confirm="pickerConfirm" @cancel="showPicker = false" />
    </van-popup>

    <div class="list" @scroll="handleScroll">
      <ul>
        <li class="list_item" v-for="item in logList" @click.stop="goDetail(item)">
          <div class="info">
            <div class="name">{{ item.name }}</div>
            <ul class="sex_ul" :style="{ background: item.gender == 1 ? '#E3EEFF' : '#FFE7E7' }">
              <li class="sex">
                <img class="sex_img" :src="item.gender == 0
                  ? require('../../assets/img/女@2x.png')
                  : require('../../assets/img/男@2x.png')
                  " alt="" />
              </li>
              <li class="info_line"></li>
              <li class="age" :style="{ color: item.gender == 1 ? '#2C80FC' : '#FF3333' }">
                {{ item.age }}
              </li>
            </ul>
            <div class="info_bed" v-if="item.areaNumber">
              {{ item.areaNumber }}区
            </div>
            <div class="info_time" v-if="item.bedNumber">
              {{ item.bedNumber }}床
            </div>
          </div>
          <!-- <div class="primary">初步诊断：头晕、胸闷、以及恶心呕吐难受...</div> -->
          <div class="hos_time">创建时间：{{ item.createTime2 }}</div>
          <!-- <div class="id_no">病案号：20240045000555</div> -->
          <div class="line"></div>
          <div class="type">
            <div class="tag_pre">
              <span class="tag_content">{{
                item.type == "1"
                  ? "预诊"
                  : item.type == "2"
                    ? "问诊"
                    : item.type == "3"
                      ? "入院首程"
                      : item.type == "4"
                        ? "查房记录"
                        : item.type == "5"
                          ? "手术小结"
                          : item.type == "6"
                            ? "术前谈话记录"
                            : item.type == "7"
                              ? "阶段小结"
                              : ""
              }}</span>
            </div>
            <!-- 动态绑定class，判断是否为'已手术' -->
            <span :class="{
              tag_desc_surgery: isSurgery(item),
              tag_desc_no_surgery: !isSurgery(item),
            }">
              {{
                item.operationDate !== "" &&
                  item.operationDate !== null &&
                  item.operationDate !== undefined
                  ? "已手术"
                  : "无手术"
              }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getLogList } from "@/api/log.js";
export default {
  data() {
    return {
      surgeryStatus: "已手术", // 或 '已手术'
      logList: [],
      total: "",
      date: "", //this.getCurrentDate(),
      show: false,
      value: "",
      pageNum: 0,
      pages: 1,
      pageSize: 10,
      isLoading: false,
      pickValue: "",
      columns: ["全部类型", "病程录入", "快速新增"],
      statuscolumns: [
        {
          id: 0,
          label: "全部类型",
        },
        {
          id: 1,
          label: "病程录入",
        },
        {
          id: 2,
          label: "快速新增",
        },
      ],
      flag: 0,
      showPicker: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2099, 0, 31),
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    // 使用 window 监听滚动事件
    window.addEventListener('scroll', this.throttle(this.handleScroll, 200));
  },
  beforeDestroy() {
    // 移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    throttle(func, delay) {
      let timer = null;
      return function () {
        if (!timer) {
          timer = setTimeout(() => {
            func.apply(this, arguments);
            timer = null;
          }, delay);
        }
      };
    },
    getList() {
      if (this.isLoading) return; // 如果已经在加载中，直接返回
      this.isLoading = true; // 设置为正在加载

      // 如果是新的查询（即 pageNum 为 0），则清空 logList
      if (this.pageNum === 0) {
        this.logList = [];
      }

      const params = {
        flag: this.flag,
        createTime: this.date,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };

      getLogList(params).then((res) => {
        if (res.data.list && res.data.list.length > 0) {
          // 追加数据
          this.logList = this.logList.concat(res.data.list);
        } else if (res.data.list.length === 0) {
          // 如果是新的查询且没有数据，则保留 logList 为空数组
          this.logList = [];
        }

        this.total = res.data.total;
        this.isLoading = false; // 加载完成后恢复状态
      }).catch(() => {
        this.isLoading = false; // 发生错误也要恢复状态
      });
    },

    handleScroll(event) {
      const container = event.target;
      // 判断是否滚动到底部
      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
        this.loadMore();
      }
    },

    loadMore() {
      if (this.logList.length >= this.total || this.isLoading) {
        return; // 已加载完成或正在加载中
      }
      this.pageNum++;
      this.getList();
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以要+1
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    onConfirm(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      this.date = time;
      this.show = false;
      this.pageNum = 0;
      this.getList();
    },
    pickerConfirm(pickValue, index) {
      this.pickValue = pickValue;
      this.flag = this.statuscolumns[index].id;
      this.showPicker = false;
      this.pageNum = 0;
      this.getList();
    },
    resetDate() {
      this.date = '';
      this.pageNum = 0;
      this.getList();
    },
    goDetail(v) {
      if (v.flag == "2") {
        this.$router.push({
          path: `/popup/workDetail`,
          query: {
            patientId: v.patientId,
            recordId: v.id,
          },
        });
      } else {
        this.$router.push({
          path: `/popup/diseaseDetail`,
          query: {
            patientId: v.patientId,
            recordId: v.id,
          },
        });
      }
    },
  },
  computed: {
    // 修改为函数，传入当前的 item
    isSurgery() {
      return (item) =>
        item.operationDate !== "" &&
        item.operationDate !== null &&
        item.operationDate !== undefined;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 345px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
  border-radius: 10px 10px 10px 10px;
  margin-top: 10px;
}

.condition {
  width: 345px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.select {
  width: 110px;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.all {
  width: 56px;
  height: 20px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 14px;
  color: #989898;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-right: 2px;
}

.arrow {
  width: 12px;
  height: 12px;
}

.time {
  background-color: rgba(247, 247, 247, 1);
  border-radius: 4px;
  width: 120px;
  height: 26px;
  border: 1px solid rgba(238, 238, 238, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.time_icon {
  width: 14px;
  height: 14px;
  margin: 0 5px;
}

.time_value {
  width: 67px;
  height: 19px;
  overflow-wrap: break-word;
  color: rgba(152, 152, 152, 1);
  font-size: 13px;
  font-family: Source Han Sans-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 19px;
}

.list {
  margin-top: 10px;
  height: 820px;
  /* 设置为你期望的高度 */
  overflow-y: auto;
  /* 确保内容可以垂直滚动 */
}

.list_item {
  width: 345px;
  height: 113px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
}

.info {
  display: flex;
  align-items: center;
}

.name {
  height: 25px;
  width: 98px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 17px;
  color: #1a1a1a;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  padding: 10px 15px;
  display: inline-block;
  /* 使元素成为内联块级元素 */
  max-width: 68px;
  /* 4个字符 * 17px字体大小 */
  white-space: nowrap;
  /* 防止文本换行 */
  overflow: hidden;
  /* 隐藏超出部分 */
  text-overflow: ellipsis;
  /* 添加省略号 */
}

.info>ul {
  display: flex;
  width: 34px;
  height: 16px;
  background: #ffe7e7;
  border-radius: 3px 3px 3px 3px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.info_bed {
  width: 58px;
  height: 16px;
  background: #e3eeff;
  border-radius: 3px 3px 3px 3px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 500;
  font-size: 11px;
  color: #2c80fc;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.info_line {
  background-color: rgba(221, 214, 214, 0.68);
  width: 1px;
  height: 10px;
}

.sex {
  width: 13px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sex_img {
  width: 10px;
  height: 10px;
}

.age {
  width: 13px;
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(255, 51, 51, 1);
  font-size: 11px;
  font-family: Source Han Sans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 16px;
  margin: 0 1px;
}

.info_time {
  width: 47px;
  height: 17px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 17px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  margin-left: 90px;
}

.primary,
.hos_time,
.id_no {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 13px;
  color: #999999;
  line-height: 19px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  margin-left: 15px;
  margin-bottom: 3px;
}

.line {
  position: relative;
  width: 345px;
  height: 0;
  /* 设置高度为0，因为我们用伪类来绘制线 */
}

.line::before {
  content: "";
  /* 必须有content才能使伪类生效 */
  position: absolute;
  top: 50%;
  /* 垂直居中 */
  left: 0;
  right: 0;
  height: 1px;
  /* 线的高度 */
  background: #f0f0f0;
  /* 线的颜色 */
}

.line::after {
  display: block;
}

.type {
  width: 345px;
  height: 26px;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.tag_pre {
  width: 120px;
  height: 26px;
  background: linear-gradient(90deg,
      #fff0d6 0%,
      #fff7e8 50%,
      rgba(255, 255, 255, 0) 100%);
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
}

.tag_as {
  width: 120px;
  height: 26px;
  background: linear-gradient(90deg,
      #d5fae8 0%,
      #e2fcef 50%,
      rgba(255, 255, 255, 0) 100%);
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.tag_content {
  width: 52px;
  height: 19px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 500;
  font-size: 13px;
  color: #f6a00d;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin: 15px;
}

.tag_desc_no_surgery {
  width: 39px;
  height: 19px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 13px;
  color: #f6a00d;
  /* 无手术颜色 */
  line-height: 19px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  margin-left: 171px;
}

.tag_desc_surgery {
  width: 39px;
  height: 19px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 13px;
  color: #00c763;
  /* 已手术颜色 */
  line-height: 19px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  margin-left: 171px;
}
</style>
