<template>
  <div class="container">
    <div class="condition">
      <div class="select">
        <span class="all" @click="showPicker = true">{{ pickValue }}</span>
        <img class="arrow" src="../../assets/img/箭头@2x.png" alt="" />
      </div>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="pickerConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>

    <div class="list">
      <ul>
        <li
          class="list_item"
          v-for="item in intelligentList"
          @click.stop="goDetail(item)"
        >
          <div class="info">
            <div class="name">{{ item.name }}</div>
            <ul
              class="sex_ul"
              :style="{ background: item.gender == 1 ? '#E3EEFF' : '#FFE7E7' }"
            >
              <li class="sex">
                <img
                  class="sex_img"
                  :src="
                    item.gender == 0
                      ? require('../../assets/img/女@2x.png')
                      : require('../../assets/img/男@2x.png')
                  "
                  alt=""
                />
              </li>
              <li class="info_line"></li>
              <li
                class="age"
                :style="{ color: item.gender == 1 ? '#2C80FC' : '#FF3333' }"
              >
                {{ item.age }}
              </li>
            </ul>
            <div class="info_bed" v-show="item.areaNumber || item.bedNumber">
              {{ item.areaNumber ? `${item.areaNumber}区` : "" }}
              {{ item.bedNumber ? `${item.bedNumber}床` : "" }}
            </div>
            <!-- <div class="info_time">{{ item.dischargeDate?'出院':'未出院' }}</div> -->
          </div>
          <div class="primary">初步诊断：{{ item.primaryDiagnosis }}</div>
          <div class="hos_time">住院日期：{{ item.admissionDate }}</div>
          <div class="id_no">病案号：{{ item.medicalRecordNumber }}</div>
          <div class="line"></div>
          <div class="type">
            <div class="tag_pre">
              <span class="tag_content">入院首程</span>
            </div>
            <!-- 动态绑定class，判断是否为'已手术' -->
            <span
              :class="{
                tag_desc_surgery: isSurgery(item),
                tag_desc_no_surgery: !isSurgery(item),
              }"
            >
              {{
                item.operationDate !== "" &&
                item.operationDate !== null &&
                item.operationDate !== undefined
                  ? "已手术"
                  : "无手术"
              }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getIntelligentList } from "@/api/intelligent.js";
export default {
  data() {
    return {
      surgeryStatus: "已手术", // 或 '已手术'
      intelligentList: [],
      tota: "",
      date: "", //this.getCurrentDate(),
      show: false,
      value: "",
      pickValue: "未出院",
      columns: ["未出院", "已出院"],
      statuscolumns: [
        {
          id: false,
          label: "未出院",
        },
        {
          id: true,
          label: "已出院",
        },
      ],
      flag: false,
      showPicker: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      var params = {
        isDischarged: this.flag,
      };
      getIntelligentList(params).then((res) => {
        console.log(res);
        //this.intelligentList = res;
      });
    },
    pickerConfirm(pickValue, index) {
      this.pickValue = pickValue;
      this.flag = this.statuscolumns[index].id;
      this.showPicker = false;
      this.getList();
    },
    goDetail(v) {
      // console.log(v);
      // this.$router.push({
      //   path: `/popup/diseaseDetail`,
      //   query: {
      //     patientId: v.patientId,
      //     recordId: v.id,
      //   },
      // });
    },
  },
  computed: {
    isSurgery() {
      return (item) =>
        item.operationDate !== "" &&
        item.operationDate !== null &&
        item.operationDate !== undefined;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 345px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
  border-radius: 10px 10px 10px 10px;
  margin-top: 10px;
}

.condition {
  width: 345px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.select {
  width: 60px;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

.all {
  width: 56px;
  height: 20px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 14px;
  color: #989898;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-right: 2px;
}

.arrow {
  width: 12px;
  height: 12px;
}

.time {
  background-color: rgba(247, 247, 247, 1);
  border-radius: 4px;
  width: 110px;
  height: 26px;
  border: 1px solid rgba(238, 238, 238, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.time_icon {
  width: 14px;
  height: 14px;
  margin: 0 5px;
}

.time_value {
  width: 67px;
  height: 19px;
  overflow-wrap: break-word;
  color: rgba(152, 152, 152, 1);
  font-size: 13px;
  font-family: Source Han Sans-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 19px;
}

.list {
  margin-top: 10px;
}

.list_item {
  width: 345px;
  height: 163px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
}

.info {
  display: flex;
  align-items: center;
}

.name {
  height: 25px;
  width: 98px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 17px;
  color: #1a1a1a;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  padding: 10px 15px;
  display: inline-block;
  /* 使元素成为内联块级元素 */
  max-width: 68px;
  /* 4个字符 * 17px字体大小 */
  white-space: nowrap;
  /* 防止文本换行 */
  overflow: hidden;
  /* 隐藏超出部分 */
  text-overflow: ellipsis;
  /* 添加省略号 */
}

.info > ul {
  display: flex;
  width: 34px;
  height: 16px;
  background: #ffe7e7;
  border-radius: 3px 3px 3px 3px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.info_bed {
  width: 58px;
  height: 16px;
  background: #e3eeff;
  border-radius: 3px 3px 3px 3px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 500;
  font-size: 11px;
  color: #2c80fc;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.info_line {
  background-color: rgba(221, 214, 214, 0.68);
  width: 1px;
  height: 10px;
}

.sex {
  width: 13px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sex_img {
  width: 10px;
  height: 10px;
}

.age {
  width: 13px;
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(255, 51, 51, 1);
  font-size: 11px;
  font-family: Source Han Sans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 16px;
  margin: 0 1px;
}

.info_time {
  width: 47px;
  height: 17px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 17px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  margin-left: 90px;
}

.primary,
.hos_time,
.id_no {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 13px;
  color: #999999;
  line-height: 19px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  margin-left: 15px;
  margin-bottom: 3px;
}

.line {
  position: relative;
  width: 345px;
  height: 0;
  /* 设置高度为0，因为我们用伪类来绘制线 */
}

.line::before {
  content: "";
  /* 必须有content才能使伪类生效 */
  position: absolute;
  top: 50%;
  /* 垂直居中 */
  left: 0;
  right: 0;
  height: 1px;
  /* 线的高度 */
  background: #f0f0f0;
  /* 线的颜色 */
}

.line::after {
  display: block;
}

.type {
  width: 345px;
  height: 26px;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.tag_pre {
  width: 120px;
  height: 26px;
  background: linear-gradient(
    90deg,
    #fff0d6 0%,
    #fff7e8 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
}

.tag_as {
  width: 120px;
  height: 26px;
  background: linear-gradient(
    90deg,
    #d5fae8 0%,
    #e2fcef 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.tag_content {
  width: 52px;
  height: 19px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 500;
  font-size: 13px;
  color: #f6a00d;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin: 15px;
}

.tag_desc_no_surgery {
  width: 39px;
  height: 19px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 13px;
  color: #f6a00d;
  /* 无手术颜色 */
  line-height: 19px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  margin-left: 171px;
}

.tag_desc_surgery {
  width: 39px;
  height: 19px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 13px;
  color: #00c763;
  /* 已手术颜色 */
  line-height: 19px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  margin-left: 171px;
}
</style>
