<template>
    <div class="container">
        <div class="condition">
            <div class="select" @click="showPicker = true">
                <span class="all">{{ statuscolumns[selectIndex].label }}</span>
                <img class="arrow" src="../../assets/img/箭头@2x.png" alt="">
            </div>
            <div class="time" @click="show = true">
                <img class="time_icon" referrerpolicy="no-referrer" src="../../assets/img/日历@2x.png" />
                <span class="time_value">{{ date }}</span>
            </div>
        </div>

        <van-calendar v-model="show" @confirm="onConfirm" color="#1989fa" />
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="columns" @confirm="pickerConfirm" @cancel="showPicker = false" />
        </van-popup>

        <div class="list">
            <ul>
                <li class="list_item" v-for="item in diagnosisList" :key="item.id">
                    <div class="info">
                        <div class="name">{{item.sname}}</div>
                        <ul class="sex_ul" :style="{ background: item.sex == 1 ? '#E3EEFF' : '#FFE7E7' }">
                            <li class="sex">
                                <img class="sex_img" :src="item.sex == 0 ? require('../../assets/img/女@2x.png') : require('../../assets/img/男@2x.png')" alt="">
                            </li>
                            <li class="info_line"></li>
                            <li class="age" :style="{ color: item.sex == 1 ? '#2C80FC' : '#FF3333' }">{{item.age}}</li>
                        </ul>
                        <div class="info_time">{{item.isCreateEmr=='0'?'待生成':'生成成功'}}</div>
                    </div>
                    <div class="hos_time">创建日期：{{item.credate}}</div>
                    <div class="id_no">病案号：{{item.binganhao}}</div>
                    <div class="line"></div>
                    <div class="type">
                        <div class="tag_pre">
                            <span class="tag_content">{{item.type =='1'?'预诊病历':'问诊病历' }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { getDiagnosisList } from "@/api/diagnosis.js";
export default {
    data() {
        return {
            diagnosisList: [],
            date: '',//this.getCurrentDate(),
            show: false,
            value: '',
            pickValue: '',
            columns: ['全部病历', '预诊病历', '问诊病历'],
            statuscolumns: [
                {
                    id: '',
                    label: "全部病历"
                },
                {
                    id: 1,
                    label: "预诊病历"
                },
                {
                    id: 2,
                    label: "问诊病历"
                }
            ],
            selectIndex: 0,
            medicalId: '',
            showPicker: false,
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            var diagnosisSearchReq = {
                isCreateEmr: '',
                uuid: '',
                type: this.medicalId,
                createTime: this.date,
                keyword: ''
            }
            getDiagnosisList(diagnosisSearchReq).then((res) => {
                this.diagnosisList = res.rows;
            });
        },
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        onConfirm(value) {
            let date = new Date(value)
            let y = date.getFullYear()
            let m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            const time = y + '-' + m + '-' + d;
            this.date = time;
            this.show = false;
            this.getList();
        },
        pickerConfirm(pickValue, index) {
            this.pickValue = pickValue;
            this.selectIndex = index;
            this.medicalId = this.statuscolumns[index].id;
            this.showPicker = false;
            this.getList();
        },
    },
    computed: {
        
    }
}
</script>

<style lang="less" scoped>
.container {
    width: 345px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
    border-radius: 10px 10px 10px 10px;
    margin-top: 10px;
}

.condition {
    width: 345px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.select {
    width: 110px;
    height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.all {
    width: 56px;
    height: 20px;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 400;
    font-size: 14px;
    color: #989898;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-right: 2px;
}

.arrow {
    width: 12px;
    height: 12px;
}

.time {
    background-color: rgba(247, 247, 247, 1);
    border-radius: 4px;
    width: 110px;
    height: 26px;
    border: 1px solid rgba(238, 238, 238, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.time_icon {
    width: 14px;
    height: 14px;
    margin: 0 5px;
}

.time_value {
    width: 67px;
    height: 19px;
    overflow-wrap: break-word;
    color: rgba(152, 152, 152, 1);
    font-size: 13px;
    font-family: Source Han Sans-Regular;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    line-height: 19px;
}

.list {
    margin-top: 10px;
}

.list_item {
    width: 345px;
    height: 141px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
    border-radius: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;
}

.info {
    display: flex;
    align-items: center;
}

.name {
    height: 25px;
    width: 98px;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 700;
    font-size: 17px;
    color: #1A1A1A;
    line-height: 25px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    padding: 10px 15px;
    display: inline-block;
    /* 使元素成为内联块级元素 */
    max-width: 68px;
    /* 4个字符 * 17px字体大小 */
    white-space: nowrap;
    /* 防止文本换行 */
    overflow: hidden;
    /* 隐藏超出部分 */
    text-overflow: ellipsis;
    /* 添加省略号 */
}

.info>ul {
    display: flex;
    width: 34px;
    height: 16px;
    background: #FFE7E7;
    border-radius: 3px 3px 3px 3px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}


.info_bed {
    width: 58px;
    height: 16px;
    background: #E3EEFF;
    border-radius: 3px 3px 3px 3px;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 500;
    font-size: 11px;
    color: #2C80FC;
    line-height: 16px;
    text-align: center;
    font-style: normal;
    text-transform: none;
}

.info_line {
    background-color: rgba(221, 214, 214, 0.68);
    width: 1px;
    height: 10px;
}

.sex {
    width: 13px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sex_img {
    width: 10px;
    height: 10px;
}

.age {
    width: 13px;
    height: 16px;
    overflow-wrap: break-word;
    color: rgba(255, 51, 51, 1);
    font-size: 11px;
    font-family: Source Han Sans-Medium;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    line-height: 16px;
    margin: 0 1px;
}

.info_time {
    width: 57px;
    height: 17px;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    text-align: right;
    font-style: normal;
    text-transform: none;
    margin-left: 130px;
}

.primary,
.hos_time,
.id_no {
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 400;
    font-size: 13px;
    color: #999999;
    line-height: 19px;
    text-align: right;
    font-style: normal;
    text-transform: none;
    margin-left: 15px;
    margin-bottom: 3px;
}

.line {
    position: relative;
    width: 345px;
    height: 0;
    /* 设置高度为0，因为我们用伪类来绘制线 */
}

.line::before {
    content: '';
    /* 必须有content才能使伪类生效 */
    position: absolute;
    top: 50%;
    /* 垂直居中 */
    left: 0;
    right: 0;
    height: 1px;
    /* 线的高度 */
    background: #F0F0F0;
    /* 线的颜色 */
}

.line::after {
    display: block;
}

.type {
    width: 345px;
    height: 26px;
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.tag_pre {
    width: 120px;
    height: 26px;
    background: linear-gradient(90deg, #FFF0D6 0%, #FFF7E8 50%, rgba(255, 255, 255, 0) 100%);
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
}

.tag_as {
    width: 120px;
    height: 26px;
    background: linear-gradient(90deg, #D5FAE8 0%, #E2FCEF 50%, rgba(255, 255, 255, 0) 100%);
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.tag_content {
    width: 52px;
    height: 19px;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 500;
    font-size: 13px;
    color: #F6A00D;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin: 15px;
}

.tag_desc_no_surgery {
    width: 39px;
    height: 19px;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 400;
    font-size: 13px;
    color: #F6A00D;
    /* 无手术颜色 */
    line-height: 19px;
    text-align: right;
    font-style: normal;
    text-transform: none;
    margin-left: 171px;
}

.tag_desc_surgery {
    width: 39px;
    height: 19px;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 400;
    font-size: 13px;
    color: #00C763;
    /* 已手术颜色 */
    line-height: 19px;
    text-align: right;
    font-style: normal;
    text-transform: none;
    margin-left: 171px;
}
</style>