<template>
  <div class="nav_box mbform-drag">
    <ul class="menu_box">
      <li
        v-for="(item, index) in menuItems"
        :key="index"
        :class="['menu_item', { actives: activeIndex === index }]"
        @click="activateMenuItem(index, item.path)"
      >
        <!-- 根据 activeIndex 动态返回图片路径 -->
        <img :src="getImageSrc(item.src, index)" alt="" />
        <p>{{ item.label }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0, // 默认激活的项
      menuItems: [
        { label: "工作日志", src: "img/log@2x.png", path: "/log" },
        { label: "住院病程", src: "img/add@2x.png", path: "/intelligent" },
        // { label: "智能接诊", src: "img/robot@2x.png", path: "/diagnosis" },
        // { label: "详情", src: "img/log@2x.png", path: "/popup/medicalRecord" },
        // { label: "模板设置", src: "img/template@2x.png", path: "/template" },
      ],
    };
  },
  methods: {
    activateMenuItem(index, path) {
      if (this.$route.path !== path) {
        this.activeIndex = index;
        this.$router.push({ path });
      }
    },
    // 动态返回图片路径
    getImageSrc(src, index) {
      // 如果当前项被激活，则使用带 _active 的图片
      if (this.activeIndex === index) {
        return require(`@/assets/${src.replace("@2x.png", "_active@2x.png")}`);
      }
      // 否则返回原始图片
      return require(`@/assets/${src}`);
    },
  },
};
</script>

<style lang="less" scoped>
.nav_box {
  width: 70px;
  height: 830px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
}

.menu_box {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu_item {
  width: 60px;
  height: 70px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actives {
  background: #eff6ff;
  border-radius: 10px;
}

.menu_item:nth-child(1) {
  margin-top: 10px;
}

.menu_item > img {
  width: 30px;
  height: 30px;
}

.menu_item > p {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 12px;
  color: #8792a4;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

/* 让最后一个 li 在底部 */
// .menu_item:last-child {
//   margin-top: auto; /* 自动将它推到底部 */
//   margin-bottom: 10px;
// }
</style>
