<template>
    <div class="container">
        <Header></Header>
        <div class="content">
            <Nav></Nav>
            <div class="main">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
export default {
    data() {
        return {
            value: '',
            date: '',
            show: false,
        }
    },
    methods: {
        formatDate(date) {
            return `${date.getMonth() + 1}/${date.getDate()}`;
        },
        onConfirm(date) {
            this.show = false;
            this.date = this.formatDate(date);
        },
    },
    components: {
        Header,
        Nav
    },
}
</script>

<style lang="less" scoped>
.container {
    width: 435px;
    height: 900px;
    background: #F7F7F7;
    border-radius: 10px 10px 10px 10px;
}

.content {
    display: flex;
}

.condition {
    width: 345px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.05);
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.search_box {
    background-color: rgba(247, 247, 247, 1);
    border-radius: 4px;
    width: 192px;
    height: 26px;
    border: 1px solid rgba(238, 238, 238, 1);
    display: flex;
    align-items: center;
    margin: 0 8px;
}

.search {
    text-align: center;
    line-height: 26px;
    margin-left: 8px;
}

.search_icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
}

.search_value {
    width: 65px;
    height: 19px;
    overflow-wrap: break-word;
    color: rgba(152, 152, 152, 1);
    font-size: 13px;
    font-family: Source Han Sans-Regular;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
}

.main {
    width: 365px;
    height: 830px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    scroll-behavior: smooth; /* 平滑滚动 */
}
</style>